<template>
  <Layout>
    <div class="main-member">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <!-- 信息 -->
        <div class="md-member-1 color-f c-card" v-ani.fade-up>
          <div class="cnt">
            <!-- 未认证 -->
            <div class="state-1 flex-center" v-if="!valid" @click.prevent="valid = true">
              <div class="s26 font-medium">尊敬的：李四建筑采购商</div>
              <img :src="require('@/assets/images/icon-90.png')" alt="" class="icon">
              <div class="s18 font-medium">未认证状态</div>
              <BaseButton size='large' tag='router-link'>去认证</BaseButton>
            </div>
            <!-- 已认证 -->
            <div class="state-2 flex-top" v-else @click.prevent="valid = false">
              <div class="lf">
                <div class="t1 s26 font-medium">尊敬的：李四建筑采购商</div>
                <div class="t2 s18 font-medium">当前等级为：</div>
                <div class="t3 flex-middle">
                  <span class="s26">黄帽</span>
                  <span class="icon flex-middle">
                    <img :src="require('@/assets/images/icon-start4.png')" alt="">
                    <img :src="require('@/assets/images/icon-start4.png')" alt="">
                    <img :src="require('@/assets/images/icon-start4.png')" alt="">
                    <img :src="require('@/assets/images/icon-start4.png')" alt="">
                  </span>
                  <span class="18">当前支持：账期一个月</span>
                </div>
              </div>
              <img :src="require('@/assets/images/icon-91.png')" alt="" class="img">
            </div>
          </div>
        </div>
        <!-- 权益说明 -->
        <div class="md-member-2 c-card" v-ani.fade-up>
          <div class="hd flex-middle">
            <div class="c-title-2 s22">权益说明</div>
            <div class="s16 color-9">注:如遇定制产品，必须在预付款比例上+15%的支付金额</div>
          </div>
          <div class="table">
            <div class="thead s16 color-f bg-green font-medium">
              <div class="tr">
                <div class="td">等级</div>
                <div class="td">预付款</div>
                <div class="td">账期</div>
              </div>
            </div>
            <div class="tbody s16 color-4">
              <template v-for="item in list">
                <div class="tr" :key="item.title">
                  <!-- 等级 -->
                  <div class="td">
                    <img :src="getHatImg(item.type)" alt="" class="icon">
                    <span>{{item.title}}</span>
                  </div>
                  <!-- 预付款 -->
                  <div class="td">{{item.text1}}</div>
                  <!-- 账期 -->
                  <div class="td">{{item.text2}}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- 详情 -->
        <div class="md-member-3">
          <template v-for="(group, i1) in list2">
            <div class="c-card item s16" :key="i1" v-ani.fade-up>
              <template v-for="(item, i2) in group">
                <div class="tr" :key="i2">
                  <div class="td">
                    <p class="tit s16 color-4">{{item.title}}</p>
                    <div class="star flex-center">
                      <img
                        :src="getStarImg(item.type)"
                        v-for="star in item.num"
                        :key="star"
                        alt=""
                      >
                    </div>
                  </div>
                  <div class="td">
                    <div v-html="item.content"></div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <!-- 如有疑问，请咨询客服 -->
        <div class="md-member-4" v-ani.fade-up>
          <router-link class="link s18 color-4 font-medium" to="">
            <span>如有疑问，请咨询客服</span>
            <div class="icon flex-center">
              <img :src="require('@/assets/images/icon-kefu.png')" alt="">
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          name: '首页',
          link: '/'
        }, {
          name: '会员等级讲解',
          link: ''
        }
      ],
      valid: false,
      list: [
        {
          type: 1,
          title: '黄帽',
          text1: '30%',
          text2: '支持1个月账期'
        }, {
          type: 2,
          title: '红帽',
          text1: '20%',
          text2: '支持2个月账期'
        }, {
          type: 3,
          title: '蓝帽',
          text1: '10%',
          text2: '支持3个月账期'
        }, {
          type: 4,
          title: '白帽',
          text1: '0%',
          text2: '支持3个月账期'
        }
      ],
      list2: [
        [
          {
            title: '黄帽', type: 1, num: 1,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
            `
          }, {
            title: '黄帽', type: 1, num: 2,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>5万</span>以内的<span class='color-red'>1个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>5家供应商</span>报价</p>
            `
          }, {
            title: '黄帽', type: 1, num: 3,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>10万</span>以内的<span class='color-red'>1个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>5家供应商</span>报价</p>
            `
          }, {
            title: '黄帽', type: 1, num: 4,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>20万</span>以内的<span class='color-red'>1个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>10家供应商</span>报价</p>
            `
          }, {
            title: '黄帽', type: 1, num: 5,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>30万</span>以内的<span class='color-red'>1个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>10家供应商</span>报价</p>
            `
          }
        ], [
          {
            title: '红帽', type: 2, num: 1,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>40万</span>以内的<span class='color-red'>2个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>15家供应商</span>报价</p>
            `
          }, {
            title: '红帽', type: 2, num: 2,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>80万</span>以内的<span class='color-red'>2个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>15家供应商</span>报价</p>
            `
          }, {
            title: '红帽', type: 2, num: 3,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>120万</span>以内的<span class='color-red'>2个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>20家供应商</span>报价</p>
            `
          }, {
            title: '红帽', type: 2, num: 4,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>200万</span>以内的<span class='color-red'>2个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>20家供应商</span>报价</p>
            `
          }, {
            title: '红帽', type: 2, num: 5,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>300万</span>以内的<span class='color-red'>2个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>20家供应商</span>报价</p>
            `
          }
        ], [
          {
            title: '蓝帽', type: 3, num: 1,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>30万</span>以内的<span class='color-red'>3个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>30家供应商</span>报价</p>
              <p>5、平台可免费3次协助寻找优质供应商</p>
            `
          }, {
            title: '蓝帽', type: 3, num: 2,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>400万</span>以内的<span class='color-red'>3个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>30家供应商</span>报价</p>
            `
          }, {
            title: '蓝帽', type: 3, num: 3,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>500万</span>以内的<span class='color-red'>3个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>5家供应商</span>报价</p>
            `
          }, {
            title: '蓝帽', type: 3, num: 4,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>600万</span>以内的<span class='color-red'>3个月</span>账期</p>
              <p>4、发布的报价允许<span class='color-blue'>10家供应商</span>报价</p>
            `
          }, {
            title: '蓝帽', type: 3, num: 5,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>700万</span>以内的<span class='color-red'>3个月</span>账期</p>
            `
          }
        ], [
          {
            title: '白帽', type: 4, num: 1,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>800万</span>以内的<span class='color-red'>3个月</span>账期</p>
              <p>4、平台可免费5次协助寻找优质供应商</p>
            `
          }, {
            title: '白帽', type: 4, num: 2,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>1000万</span>以内的<span class='color-red'>3个月</span>账期</p>
            `
          }, {
            title: '白帽', type: 4, num: 3,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>1200万</span>以内的<span class='color-red'>3个月</span>账期</p>
            `
          }, {
            title: '白帽', type: 4, num: 4,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>1500万</span>以内的<span class='color-red'>3个月</span>账期</p>
            `
          }, {
            title: '白帽', type: 4, num: 5,
            content: `
              <p>1、发布采购需求</p>
              <p>2、商城自由采购</p>
              <p>3、<span class='color-red'>2000万</span>以内的<span class='color-red'>3个月</span>账期</p>
            `
          }
        ]
      ]
    }
  },
  methods: {
    getHatImg(type) {
      switch (type) {
        case 1: return require('@/assets/images/hat-yellow.png');
        case 2: return require('@/assets/images/hat-red.png');
        case 3: return require('@/assets/images/hat-blue.png');
        case 4: return require('@/assets/images/hat-white.png');
        default: return '';
      }
    },
    getStarImg(type) {
      switch (type) {
        case 1: return require('@/assets/images/icon-star-yellow.png');
        case 2: return require('@/assets/images/icon-star-red.png');
        case 3: return require('@/assets/images/icon-star-blue.png');
        case 4: return require('@/assets/images/icon-star-white.png');
        default: return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-member-1{
  background: url(~@/assets/images/bg3.jpg) no-repeat center / cover;
  padding: .6rem 1rem;
  .cnt{
    background: rgba($color: #d3f4ff, $alpha: .15);
    border-radius: .04rem;
  }
  .state-1{
    height: 2.3rem;
    .icon{
      margin-left: 1.17rem; margin-right: 0.09rem;
    }
    .base-button{
      min-width: 1rem;
      margin-left: 0.37rem;
    }
  }
  .state-2{
    min-height: 2.3rem;
    .img{
      width: 1.23rem;
      margin-top: 0.71rem; margin-right: .76rem;
    }
    .lf{
      flex: 1;
      padding-top: .56rem; padding-left: 0.4rem;
    }
    .t1{

    }
    .t2{
      margin-top: .21rem;
    }
    .t3{
      margin-top: .09rem;
      .icon{
        margin-left: 0.15rem; margin-right: 0.18rem;
        img{
          width: .22rem;
          margin-right: 0.08rem;
        }
      }
    }
  }
}
.md-member-2{
  margin-top: .18rem;
  padding: .28rem .3rem .3rem;
  .hd{
    .c-title-2{
      margin-right: .19rem;
    }
  }
  .table{
    margin-top: .15rem;
    text-align: center;
    border: solid 1px #d9f4de;
    border-radius: .04rem; overflow: hidden;
    .tr{
      display: flex;
    }
    .td{
      padding: .146rem 1em;
      flex: 1;
      *{
        vertical-align: middle;
      }
    }
    .tbody{
      .icon{
        width: 0.26rem; margin-right: .02rem;
      }
      .tr{
        border-top: solid 1px #d9f4de;
        &:nth-of-type(2n){
          background: #f7fdf9;
        }
      }
      .td{
        padding: .128rem 1em;
      }
    }
  }
}
.md-member-3{
  margin-top: 0.22rem;
  display: flex; flex-wrap: wrap; justify-content: space-between;
  .item{
    width: 7.9rem; margin-bottom: 0.2rem;
    .tr{
      display: flex;
      border-bottom: 1px solid #e6f2f8;
      min-height: 1.65rem;
      &:last-of-type{
        border: none;
      }
    }
    .td{
      border-right: 1px solid #e6f2f8;
      &:nth-child(1){
        width: 1.4rem;
        text-align: center;
        padding-top: .28rem;
      }
      &:last-of-type{
        flex: 1;
        padding: .05rem .24rem;
        line-height: calc(30 / 16 * 1em);
        display: flex; align-items: center;
      }
    }
    .tit{}
    .star{
      margin-top: 0.1rem;
      img{
        width: .13rem;
        margin-right: .05rem;
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
}
.md-member-4{
  margin: .2rem 0 .4rem;
  text-align: center;
  .link{
    display: inline-flex; justify-content: center; align-items: center;
    &:hover{
      color: $blue;
    }
  }
  .icon{
    width: .4rem; height: 0.4rem;
    background: $blue;
    border-radius: 50%;
    margin-left: 0.1rem;
    img{
      width: .17rem;
    }
  }
}
</style>